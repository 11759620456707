import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import parkinsons from "../../Assets/Projects/parkinsons.jpg";
import foodify from "../../Assets/Projects/foodify.png";
import sleep from "../../Assets/Projects/sleep.jpg";
import plot from "../../Assets/Projects/plot.png";
import melomatch from "../../Assets/Projects/melomatch.jpg";
import autism from "../../Assets/Projects/autism.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={plot}
              isBlog={false}
              title="Climate Model"
              description="This web application allows the user to analyze, visualize, and predict global surface temperature anomalies. It also forecasts the carbon dioxide emissions of ten countries up to the year 2050."
              ghLink="https://github.com/yashnilsaha/climatemodel"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={autism}
              isBlog={false}
              title="Autism Classification Model"
              description="Implemented custom versions of two machine learning algorithms: decision trees and logistic regression to diagnose autism in toddlers based on features from the Q-Chat-10."
              ghLink="https://github.com/yashnilsaha/Autism-Classification-Model-for-Toddlers"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sleep}
              isBlog={false}
              title="Sleep Staging Analysis"
              description="Used a Hidden Markov Model and the Viterbi Algorithm to automate human sleep staging analysis. Also utilized the techniques of Fast Fourier Transform and Vector Quantization in the process."
              ghLink="https://github.com/yashnilsaha/Sleep-Staging-Analysis"
              demoLink="https://wp0.vanderbilt.edu/youngscientistjournal/article/automating-human-sleep-staging-analysis-from-polsomnographic-recordings-with-a-discrete-hidden-markov-predictive-model"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={parkinsons}
              isBlog={false}
              title="Parkinson's Disease Diagnosis"
              description="Created an Artificial Neural Network to diagnose and predict the severity of Parkinson's disease and a related disorder, levodopa-induced dyskinesia."
              ghLink="https://github.com/yashnilsaha/Parkinsons-Disease-Diagnosis"
              demoLink="https://wp0.vanderbilt.edu/youngscientistjournal/article/deep-learning-pose-estimation-model-for-parkinsonism-and-levodopa-induced-dyskinesia"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={melomatch}
              isBlog={false}
              title="MeloMatch"
              description="A website to allow users to quickly create playlists of a particular genre by selecting between pairs of songs. A recommendation system is used to then select the next pair based on the previous choice."
              ghLink="https://github.com/sanj6y/MeloMatch"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={foodify}
              isBlog={false}
              title="Foodify"
              description="Using an app that runs on their phone, the user can take a picture of the food they eat and upload it. One model identifies the primary food present in the image and offers some healthier alternatives to it. Another model classifies the three main categories of food present in the image and uses them to determine whether the meal is balanced."
              ghLink="https://github.com/nutritionhackathon/crossroads-app"   
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
